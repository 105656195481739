import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { createForm } from 'rc-form';
import { postRequestOTP, verifyOTP, become } from '../actions/session';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '../components/Element/Button';
import OTPInput from '../components/OTPInput';
import { makeStyles } from '@material-ui/core/styles';
import Layout from '../components/Layout';
import { navigate } from 'gatsby';
import moment from 'moment';
import { numberWithCommas } from '../helpers/utility';
import * as Api from '../apis';

const useStyles = makeStyles(theme => ({
  otp: {
    marginTop: '30px',
  },
  red: {
    color: 'red',
  },
}));

const OtpPage = ({ form, session, postRequestOTP, verifyOTP }) => {
  const classes = useStyles();

  const [otp, setOTP] = useState('');
  const [token, setToken] = useState('');
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [count, setCount] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    Api.getUserQuestionnaireCount().then(data => {
      setCount(data.count);
    });
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        setPhone(values.phone);
        handleRequestOTP(values.phone);
      }
    });
  };

  const passOtpAction = () => {
    const returnUri = localStorage.getItem('returnUri');
    navigate(returnUri);
  };

  const handleRequestOTP = phone => {
    postRequestOTP(phone).then(result => {
      setLoading(true);
      setToken(result.data.token);
    });
  };

  const handleOTPChange = value => {
    setError(false);
    setOTP(value);
    if (value.toString().length === 4) {
      verifyOTP({ pin: value, phone, otp_token: token })
        .then(() => {
          window.alert('ยืนยันเรียบร้อย');
          passOtpAction();
          dispatch(become());
        })
        .catch(() => {
          setLoading(false);
          setError(true);
        });
    }
  };

  // if (session.user && session.user.is_verify) {
  //   passOtpAction();
  //   return false;
  // }

  return (
    <Layout>
      <Box>
        <h3 align='center'>
          <span className={classes.red}>*</span>“หมอออนไลน์ : Mor Online ”
        </h3>
        <h3 align='center'>ระบบ Big Data ด้านสุขภาพของคนไทย</h3>
        <h3 align='center'>เรามีการพัฒนา และ ปรับปรุงระบบตลอดเวลา</h3>
        <h3 align='center'>
          ร่วมกับกลุ่มตัวอย่างคนไทยกว่า{' '}
          <span className={classes.red}>{numberWithCommas(count)}</span> Case
        </h3>
        <h5 align='center' className={classes.red}>
          *Realtime Update {moment().format('DD/MM/YYYY')}
        </h5>
      </Box>
      <Box>
        <h2 align='center' className={classes.red}>
          ให้เราดูแลท่าน
        </h2>
        <h3 align='center'>ข้อมูลของท่าน จะถูกจัดเก็บเป็นความลับ(PDPA)</h3>
        <h3 align='center'>หากการประเมิน หรือ การกระทำใดของท่าน</h3>
        <h3 align='center'>เข้าเกณฑ์เสี่ยงด้านสุขภาพ</h3>
        <h3 align='center'>อาจมีระบบแจ้งเตือน หรือ มีเจ้าหน้าที่ติดต่อกลับ</h3>
      </Box>
      <Container maxWidth='sm'>
        <Box my={2}>
          {!token ? (
            <>
              <h2 align='center'>กรุณากรอกเบอร์โทร</h2>
              <Grid container spacing={1} justifyContent='center'>
                <Grid item xs={12} sm={6}>
                  {form.getFieldDecorator('phone', {
                    rules: [
                      {
                        required: true,
                        len: 10,
                      },
                    ],
                  })(
                    <TextField
                      fullWidth
                      className='hide-spinner'
                      id='outlined-dense'
                      label='eg. 081-xxx-xxxx'
                      margin='dense'
                      variant='outlined'
                      type='number'
                      inputProps={{
                        style: { textAlign: 'center', fontSize: '2.3em' },
                      }}
                      disabled={loading}
                      error={form.getFieldError('phone')}
                    />,
                  )}
                </Grid>
              </Grid>
              <Box my={4}>
                <Grid container justify='center'>
                  <Button
                    type='fab'
                    variant='extended'
                    color='primary'
                    onClick={handleSubmit}
                    loading={loading}
                  >
                    ขอรหัสผ่านจาก SMS
                  </Button>
                </Grid>
              </Box>
            </>
          ) : (
            <Box my={2}>
              <Grid container alignItems='center' direction='column'>
                <h2>กรุณากรอกรหัส 4 หลักที่ได้จาก sms</h2>
                <OTPInput
                  OTPLength={4}
                  otpType='number'
                  className={classes.otp}
                  onChange={handleOTPChange}
                  value={otp}
                  error={error}
                  inputStyles={{
                    width: 50,
                    height: 70,
                    fontSize: '2em',
                    border: '1px solid',
                  }}
                />
              </Grid>
            </Box>
          )}
        </Box>
      </Container>
    </Layout>
  );
};

const mapStateToProps = state => ({
  session: state.session,
});

export default createForm()(
  connect(mapStateToProps, { postRequestOTP, verifyOTP })(OtpPage),
);
